<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center flex-column">
        <h1>Formulario para solicitar presupuesto</h1>
        <v-text-field filled v-model="fullName" label="Nombre y apellido" />
        <v-text-field filled v-model="email" label="Email" />
        <v-text-field filled v-model="phone" label="Telefono" />
        <v-text-field filled v-model="location" label="Localidad" />
        <v-textarea filled v-model="description" label="Observacion" />
        <v-btn @click="postForm">Enviar</v-btn>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="6" class="d-flex flex-column justify-center align-center">
        <a
          href="https://www.boe.es/buscar/act.php?id=BOE-A-2018-16673"
          target="_blank"
          >Ley de protección de datos</a
        >
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark v-bind="attrs" v-on="on">
              Mas información
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Normativa obligatoria
            </v-card-title>

            <v-card-text>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>Responsables</strong></v-list-item-title
                  >
                  Son corresponsables del tratamiento de sus datos personales:
                  El Mago Merlín Cerrajería
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>Finalidades</strong></v-list-item-title
                  >
                  Prestarle los servicios que nos ha solicitado, atender sus
                  solicitudes de información y enviarle comunicaciones
                  comerciales.
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>Cesiones</strong></v-list-item-title
                  >
                  Sus datos podrán ser comunicados a los diferentes
                  corresponsables del tratamiento para la mejor atención de sus
                  necesidades. No se cederán a otros terceros salvo obligación
                  legal.
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>Derechos</strong></v-list-item-title
                  >
                  Tiene derecho a acceder, rectificar y suprimir los datos, así
                  como otros derechos, indicados en la información adicional,
                  que puede ejercer en solucionesmerlin.com
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>Información adicional</strong></v-list-item-title
                  >
                  Puede disponer de información adicional y detallada sobre
                  Protección de Datos en solucionesmerlin.com
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <span class="text-justify">
                    Este mensaje de correo electrónico y sus archivos adjuntos
                    son confidenciales y están legalmente protegidos. Se dirige
                    exclusivamente al destinatario o destinatarios. No está
                    autorizado el acceso a este mensaje por otras personas, y
                    solo se permite su uso a quienes estén expresamente
                    autorizados. Si usted no es la persona a la que va dirigido,
                    cualquier uso, tratamiento, información, copia o
                    distribución y cualquier acción u omisión basada en la
                    información contenida en este mensaje queda prohibida y es
                    ilegal.
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Acepto
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-theme-provider>
      <base-section id="info-alt" class="darken-4">
        <v-responsive class="mx-auto" max-width="1400">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3">
                <base-info />
              </v-col>

              <v-col cols="12" md="3">
                <base-info-card title="Dirección" />

                <base-business-contact dense />
              </v-col>

              <v-col cols="12" md="3">
                <info-features />
              </v-col>

              <v-col cols="12" md="3">
                <instagram />
              </v-col>
            </v-row>
          </v-container>
        </v-responsive>
      </base-section>
    </v-theme-provider>
  </v-container>
</template>

<script>
export default {
  name: "SectionBusinessInfo",
  components: {
    InfoFeatures: () => import("@/components/InfoFeatures"),
    Instagram: () => import("@/components/Instagram")
  },
  data() {
    return {
      fullName: "",
      email: "",
      phone: "",
      postalCode: "",
      location: "",
      description: "",
      route: "",
      dialog: false
    };
  },
  methods: {
    postForm() {
      window.open(
        `https://wa.me/+34669783741?text=Nombre: ${this.fullName} - Email: ${this.email} - Telefono: ${this.phone} - Localidad: ${this.location} - CPostal: ${this.postalCode} - CPostal: ${this.postalCode} - Observacion: ${this.description}`,
        "_blank"
      );
    }
  }
};
</script>

<style lang="sass">
#info-alt a
  text-decoration: none
</style>
